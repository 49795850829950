<template>
  <div class="CaseMain">
    <caseProjectDetail
    :caseId='caseId'
    :caseInfo='caseInfo'
    @upLoadCaseInfo='upLoadCaseInfo'></caseProjectDetail>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import caseProjectDetail from '@/components/caseProjectDetail.vue';
import {getCaseInfo} from '@/api';
export default defineComponent({
  name:"CaseMain",
  components:{
    caseProjectDetail
  },
  created(){
    // caseId
    
    this.caseId = (this.$route.params&&this.$route.params.caseId)?this.$route.params.caseId:'';
    this.getCaseInfo()
  },
  watch:{
    '$route'(to,from){
      if(to.path.includes('/case/main/details/')){
        this.upLoadCaseInfo()
      }
    }
  },
  data(){
    return {
      caseId:'',
      caseInfo:{}
    }
  },
  methods:{
    upLoadCaseInfo(){
      this.getCaseInfo()
    },
    async getCaseInfo(){
      let res = await getCaseInfo({caseId:this.caseId});
      if(res&&res.code == 200){
        this.caseInfo = res.data
      }
    }
  }
})
</script>

<style lang="scss" scoped>

</style>